import { useState, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import type { UploadFile, S3Object } from '@pledge-earth/web-components';
import { generateCDNUrl } from '@pledge-earth/web-components';
import {
  FileArrowUpIcon,
  Heading,
  Paragraph,
  TrashIcon,
  DropZone,
  FileTrigger,
  IconButton,
  Size,
  Shimmer,
  twMerge,
} from '@pledge-earth/product-language';
import type { DropEvent, DropItem } from 'react-aria';
import { useMutation } from '@apollo/client';

import type { S3FolderEnum } from '../../services/graphql/generated';
import { UploadFileDocument } from '../../services/graphql/generated';
import { showErrorToast } from '../../utils/toast';

function ImageDisplay({
  loading,
  imageList,
  onRemoveImage,
}: {
  loading: boolean;
  imageList: UploadFile[];
  onRemoveImage: () => void;
}) {
  const { formatMessage } = useIntl();
  return loading ? (
    <Shimmer className="w-16 min-h-16" />
  ) : (
    <div className="relative">
      <img
        src={imageList[0]?.url}
        alt={imageList[0]?.name}
        className="w-full h-full object-cover"
      />
      <div className="absolute inset-0 flex items-center justify-center h-full w-full rounded">
        <IconButton
          variant="default"
          size={Size.Compact}
          label={formatMessage({ id: 'add-project.file.delete' })}
          onPress={onRemoveImage}
        >
          <TrashIcon />
        </IconButton>
      </div>
    </div>
  );
}

export function ImageUpload({
  onUploadImage,
  logo,
  folder,
  title,
}: {
  onUploadImage?: (file: S3Object | null) => void;
  logo?: S3Object;
  folder: S3FolderEnum;
  title: string;
}) {
  const { formatMessage } = useIntl();

  const requiredTypes = ['image/jpeg', 'image/png'];
  const requiredSize = 5242880; // 5mb max file size

  const [uploadFile, { loading }] = useMutation(UploadFileDocument);

  const mapS3ObjectToUploadFileObject = useMemo(() => {
    if (logo) {
      return {
        uid: logo.key,
        name: (logo.key || '').split('/').pop() ?? 'default.png',
        status: 'done',
        url: generateCDNUrl({
          width: 32,
          height: 32,
          s3Object: logo as any,
        }),
      } as UploadFile;
    }
    return null;
  }, [logo]);
  const [fileList, setFileList] = useState<UploadFile[]>(
    logo ? [mapS3ObjectToUploadFileObject!] : [],
  );

  const handleBeforeUpload = (file: File): boolean => {
    const isJpgOrPng = requiredTypes.includes(file.type);
    if (!isJpgOrPng) {
      showErrorToast({
        description: formatMessage({ id: 'file.upload.format.error' }),
      });
      return false;
    }

    const isAllowedSize = file.size < requiredSize;
    if (!isAllowedSize) {
      showErrorToast({
        description: formatMessage({ id: 'file.upload.size.error.5mb' }),
      });
      return false;
    }

    return true;
  };

  const handleUpload = async (file: File): Promise<void> => {
    try {
      const result = await uploadFile({
        variables: {
          file,
          folder,
        },
      });
      if (result.data?.upload_file) {
        onUploadImage?.(result.data.upload_file as any);
        setFileList([
          {
            uid: result.data.upload_file.key,
            name: file.name,
            status: 'done',
            url: generateCDNUrl({
              width: 32,
              height: 32,
              s3Object: result.data.upload_file as any,
            }),
          } as UploadFile,
        ]);
      }
    } catch (e) {
      showErrorToast({
        description: formatMessage({ id: 'file.upload.error' }),
      });
    }
  };

  const onDrop = (e: DropEvent) => {
    e.items.forEach(async (item: DropItem) => {
      if ('getFile' in item) {
        const file = await item.getFile();
        if (file && handleBeforeUpload(file)) {
          await handleUpload(file);
        }
      }
    });
  };

  const onSelect = async (files: FileList | null) => {
    if (files && files.length > 0) {
      const file = files?.[0];
      if (handleBeforeUpload(file!)) {
        await handleUpload(file!);
      }
    }
  };

  const onRemoveImage = () => {
    onUploadImage?.(null);
    setFileList([]);
  };

  return (
    <div className="ImageUpload flex flex-col gap-4 mt-1">
      <div className="flex flex-row gap-8">
        <div className="flex flex-col items-center gap-1">
          <DropZone
            onDrop={onDrop}
            className={twMerge(
              'h-16 w-16  gap-1 overflow-hidden',
              fileList.length > 0 ? 'p-0 border-solid' : 'p-4',
            )}
          >
            {fileList.length === 0 ? (
              <FileTrigger
                allowsMultiple={false}
                acceptedFileTypes={['.jpg', '.png']}
                onSelect={onSelect}
              >
                <IconButton
                  label={formatMessage({ id: 'upload.file.label' })}
                  variant="default"
                  size={Size.Compact}
                >
                  <FileArrowUpIcon />
                </IconButton>
              </FileTrigger>
            ) : (
              <ImageDisplay
                loading={loading}
                imageList={fileList}
                onRemoveImage={onRemoveImage}
              />
            )}
          </DropZone>
        </div>
        <div className="w-3/4">
          <Heading level="h5" className="ImageUpload__upload-info__title">
            {title}
          </Heading>
          <Paragraph>
            <div>
              <FormattedMessage id="upload.file.description" />
            </div>
            <div>
              <FormattedMessage id="upload.file.type.description" />
            </div>
          </Paragraph>
        </div>
      </div>
    </div>
  );
}
